<template>
    <v-container>
        <base-breadcrumb :title="page.title" :breadcrumbs="breadcrumbs"></base-breadcrumb>
        <base-card :heading=page.title class="mb-6" v-if="!resultIsLoading">
          <v-row>
            <v-col cols=12 md=6>
              <p class="body-5 mt-5 ml-5">
                <v-icon class="body-5 mr-1">mdi-text-box-check-outline</v-icon>Aplicação: {{ result.name }}
              </p>
            </v-col>
            <v-col cols=12 md=6>
              <v-card class="text-center mr-5 my-5" outlined elevation="0">
                <v-card-title class="justify-center blue darken-1 white--text py-2 mb-4">Nota</v-card-title>
                <v-card-text style="font-size: 15px">
                  <h1 class="text-primary">Nota final: {{ result.grade }}</h1>
                  <h1 class="grey--text">Valor total da avaliação: {{ examGrade }}</h1>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=12>
              <ResultQuestionsList :headers=headers :items=result.questions />
            </v-col>
          </v-row>
        </base-card>
        <v-skeleton-loader
          v-else
          class="mx-auto"
          type="card"
      ></v-skeleton-loader>
    </v-container>
</template>

<script>
import ResultQuestionsList from '../../components/result/ResultQuestionsList.vue'
import collect from 'collect.js'

export default {
  components: { ResultQuestionsList },
  data () {
    return {
      organizationId: '',
      resultIsLoading: false,
      result: {},
      page: {
        title: 'Resultado da avaliação'
      },
      breadcrumbs: [
        { text: 'Início', disabled: false, to: '/' },
        { text: 'Avaliações', disabled: false, to: '/resolution/list' },
        { text: 'Resultado da avaliação', disabled: false, to: '/result/exams' }
      ],
      headers: [
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Título da questão', value: 'title', sortable: false },
        { text: 'Nota na questão', value: 'score', sortable: false }
      ]
    }
  },
  methods: {
    // this method is used to get the result of the exam
    getResult (params) {
      this.resultIsLoading = true
      this.$axios.get(`/organizations/${this.organiationId}/students/exams/${params.examid}/applications/${params.applicationid}/students/${params.studentid}/result`)
        .then(response => {
          this.result = response.data.data
        })
        .finally(() => (this.resultIsLoading = false))
    }
  },
  computed: {
    // this method is used to calculate the total grade of the exam
    examGrade () {
      return collect(this.result.questions).map(question => {
        return collect(question.data.wordings)
          .map(wording => wording.data.grade ? parseInt(wording.data.grade) : 0)
          .collapse()
          .sum()
      }).sum()
    }
  },
  created () {
    this.organiationId = localStorage.getItem('ACTIVE_ORGANIZATION')
    const params = this.$route.params
    this.getResult(params)
  }
}
</script>
